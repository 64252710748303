import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.syncRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.syncSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.syncFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function sendinblueReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
