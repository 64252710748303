import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SendinblueStoreEffects } from './effects';
import { sendinblueReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('sendinblue', sendinblueReducer),
    EffectsModule.forFeature([SendinblueStoreEffects]),
  ],
})
export class SendinblueStoreModule {}
