import { createAction, props } from '@ngrx/store';

export const syncRequest = createAction('[Sendinblue] Sync Request');

export const syncSuccess = createAction('[Sendinblue] Sync Success');

export const syncFailure = createAction(
  '[Sendinblue] Sync Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Sendinblue] Reset State');
