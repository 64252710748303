import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SendinblueService {
  constructor(private http: HttpClient) {}

  sync() {
    return this.http.post(`sendinblue/sync`, {});
  }
}
